import React from 'react'
import Highlights from 'components/highlights'
import styles from './highlights.module.scss'

export default () => (
  <Highlights
    title="Why invest in Helios?"
    >
    <div className={styles.highlights}>
      <div className="md:u-1/2 md:pr-20 g:pr-40">

        <div className="mb-20">
          <p className="u-bold mb-5">Market conditions</p>
          <p>
          The strategy is to take advantage of the excellent underwriting conditions.
          </p>
        </div>

        <div className="mb-20">
          <p className="u-bold mb-5">First move advantage</p>
          <p>
            No competition; high barriers to entry.
          </p>
        </div>

        <div className="mb-20">
          <p className="u-bold mb-5">Expertise</p>
          <p>
            A management team deeply experienced and networked in Lloyd’s.
          </p>
        </div>

      </div>


      <div className="md:u-1/2 md:pl-20 lg:pl-40">

        <div className="mb-20">
          <p className="u-bold mb-5">Uncorrelated to equity market movements</p>
          <p>Exposure to Lloyd’s, the world’s prime insurance market.</p>
        </div>

        <div className="mb-20">
          <p className="u-bold mb-5">Success to date</p>
          <p>
            Has outperformed Lloyd’s by 9% over the last five years.
          </p>
          <p>
            Improved prospects for underwriting profitability.
          </p>
          <p>
            Rate increases since June 2017 in aggregate in excess of 50%.
          </p>
          
        </div>
        <div className="mb-20">
          <p className="u-bold mb-5">Profitability has materialised</p>
        </div>

      </div>
    </div>
  </Highlights>
)