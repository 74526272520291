import React from 'react'
import Divider from 'components/divider'

export default () => (
  <section className="padding-md">
    <Divider color="terracotta" />
    <h2 className="mt-20 h2">
      Return on Capital Helios vs Lloyd’s Market Performance  
    </h2>
    <p class="u-bold">
      Outperformance driven by:
    </p>
    <ul className="c-checklist">
      <li className="c-checklist__item">Selection of best performing syndicates</li>
     <li className="c-checklist__item">Portfolio optimisation, maximising diversification and business mix</li>
     <li className="c-checklist__item">Diversification reduces underwriting capital requirements</li>
    </ul>
    <img alt="Chart showing Return on Capital Helios vs Lloyd’s Market Performance" src="/assets/helios-year-of-account-chart-0624.png" width="100%"/>
  </section>
)
